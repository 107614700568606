@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  margin: 0;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

input {
  border-radius: 0;
}

.defaultUserFields {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.title {
  margin: 0;
  padding: 0;
  font-size: 42px;
  line-height: 53px;
  color: #121212;
  font-weight: 450;
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(50% - 15px);
}

.lastNameRoot {
  width: calc(50% - 15px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin: 0;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin: 0;
}

.smsCode {
  margin-top: 20px;
  margin-bottom: 40px;
}

.error {
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
}

.login {
  margin: 15px 0 30px 0;
  padding: 0;
}

.loginLink {
  color: #121212;
  font-weight: 400;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.ctaButton {
  min-height: 46px;
  border-radius: 0;
  color: var(--marketplaceColor);
  background-color: var(--colorBlack);
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1.35px;
  transition: ease-in-out 0.2s;

  &:hover {
    color: var(--colorBlack);
    background-color: var(--marketplaceColor);
  }

  &:disabled:hover {
    background-color: var(--colorGrey100);
    color: var(--colorGrey700);
  }
}