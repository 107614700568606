@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.title {
  margin: 0;
  padding: 0;
  font-size: 42px;
  line-height: 53px;
  color: #121212;
  font-weight: 450;
}

input {
  border-radius: 0;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  margin: 0;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin: 0;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.signup {
  margin: 15px 0 30px 0;
  padding: 0;
}

.signupLink {
  color: #121212;
  font-weight: 400;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.ctaButton {
  min-height: 46px;
  border-radius: 0;
  color: var(--marketplaceColor);
  background-color: var(--colorBlack);
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1.35px;
  transition: ease-in-out 0.2s;

  &:hover {
    color: var(--colorBlack);
    background-color: var(--marketplaceColor);
  }

  &:disabled:hover {
    background-color: var(--colorGrey100);
    color: var(--colorGrey700);
  }
}